import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Skeleton,
  Grid2 as Grid,
} from "@mui/material";
import Linkify from "react-linkify";
import Typography from "@mui/material/Typography";
import "./style.css";
import {
  customFieldsIcons,
  customFieldsIconsMap,
} from "../../utils/TicketDetailsCommonFunction";
import { componentDecorator } from "../../utils/common_functions";

const DataTable = ({ data, isLoading }) => {
  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height={118} />;
  }
  if (!data) {
    return <div>No data available</div>;
  }

  return (
    <TableContainer
      sx={{ border: "0.1px rgba(208, 210, 218, 1)", borderRadius: "3px" }}
    >
      <Table>
        <TableBody>
          {Object.entries(data).map(([key, value], index) => (
            <TableRow key={index}>
              <TableCell sx={{ border: "1px solid #ccc" }}>
                <Grid container spacing={1}>
                  <Grid item sx={{ color: "#676B7D" }}>
                    {" "}
                    {customFieldsIcons[key] || (
                      <TextFieldsIcon fontSize="small" />
                    )}
                  </Grid>
                  <Grid item>
                    {" "}
                    <b>{key}</b>
                  </Grid>
                </Grid>
              </TableCell>

              <TableCell sx={{ border: "1px solid #ccc" }}>
                <Linkify componentDecorator={componentDecorator}>
                  {value}
                </Linkify>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TicketCustomFields = ({ tableTitle, data, isLoading }) => {
  return (
    <div className="ticket_fields">
      <Typography
        style={{
          fontWeight: 500,
        }}
      >
        {tableTitle}
      </Typography>
      <div>
        <DataTable data={data} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default TicketCustomFields;
