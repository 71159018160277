import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ClickupSymbolLogo from "../../assets/ClickupSymbolLogo.png";
import { Typography } from "@mui/material";
import ExportsIcon from "../../assets/ExportsIcon.svg";
import Button from "@mui/material/Button";
import { SlaStatus } from "../SlaStatus";
import { slaStatusMessage, firstResponseMessage } from "../TicketsTable/data";
import { CircularLoader } from "../CircularLoader";
import { REACT_APP_API_PATH as baseURL, CLICKUP_URL } from "../../config";
import {
  getShortDescription,
  checkAssignee,
  checkPriority,
  checkDueDate,
} from "../../utils/TicketsTableFunctions";
import { updateDateToHumanFormat } from "../../utils/common_functions";
import Tooltip from "@mui/material/Tooltip";

const EnhancedTableHead = ({ headCells, handleSorting }) => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align="left"
            onClick={(e) => {
              handleSorting(headCell.label, index);
            }}
            sx={{
              backgroundColor: "var(--Base-Grey-2, #f5f5f5)",
              paddingY: headCell.disablePadding ? "none" : "0rem",
              textTransform: "none",
              fontFamily: "Rubik",
              fontSize: "14px",
              fontWeight: "500",
              width: "10rem",
              color: "rgba(103, 107, 126, 1)",
              cursor: "pointer",
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const renderCellContent = (key, row) => {
  const cellValue = row[key] || "N/A";

  switch (key) {
    case "task_title":
    case "title":
    case "description":
      return (
        <Tooltip title={cellValue} placement="right-end" arrow>
          {getShortDescription(cellValue)}
        </Tooltip>
      );

    case "clickup_link":
      return (
        <a href={cellValue} target="_blank" rel="noopener noreferrer">
          <img src={ClickupSymbolLogo} alt="link" className="clickupLogo" />
        </a>
      );

    case "clickup_task_id":
      return row[key] ? (
        <a
          href={`${CLICKUP_URL}${cellValue}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={ClickupSymbolLogo} alt="link" className="clickupLogo" />
        </a>
      ) : (
        "N/A"
      );

    case "sla_status":
      return (
        <SlaStatus
          slaStatusType={cellValue}
          tooltipText={slaStatusMessage[cellValue]}
        />
      );

    case "assignee":
      return (
        <Tooltip title={cellValue} placement="right-end" arrow>
          {checkAssignee(row.assignee, row.assignee_color_code)}
        </Tooltip>
      );

    case "first_response_sla_achieved": {
      const slaStatusType = row[key] ? "Achieved" : "Breached";
      return (
        <SlaStatus
          slaStatusType={slaStatusType}
          tooltipText={firstResponseMessage[slaStatusType]}
        />
      );
    }

    case "priority":
      return <>{checkPriority(cellValue)}</>;

    case "created_at":
      return <>{updateDateToHumanFormat(cellValue)}</>;

    case "due_date":
      return <>{checkDueDate(row.due_at, row.sla_status)}</>;

    case "completed_at":
      return <>{checkDueDate(row.completed_at, "")}</>;

    default:
      return cellValue;
  }
};

export const MaterialTable = ({ ...tableProps }) => {
  const handleScroll = (e) => {
    const { clientHeight, scrollTop, scrollHeight } = e.target;

    if (
      scrollHeight - scrollTop <= clientHeight + 100 &&
      !tableProps.dataIsLoading
    ) {
      tableProps.fetchData(tableProps?.next_url, tableProps.groupKey);
    }
  };

  const highlightSelectedColumn = (colIndex) => {
    document.querySelectorAll("th").forEach((thElem, index) => {
      thElem.classList.toggle("elem_highlighted", index === colIndex);
    });
  };

  const handleOnClickTableHeader = (column, index) => {
    highlightSelectedColumn(index);
    tableProps.handleSelectedColumns(
      column,
      tableProps.handleSetSelectedColumn,
      tableProps.orderedColumn
    );
  };

  useEffect(() => {
    const urlToFetch = tableProps.url
      ? `${tableProps.url}${tableProps.parameters}`
      : `${baseURL}v1/dashboard_metric/?limit=100${tableProps.parameters}`;

    tableProps.fetchData(urlToFetch);
  }, [tableProps.parameters]);

  return (
    <div>
      <Typography
        component="div"
        sx={{
          display: tableProps.display || "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginY: 4,
        }}
      >
        <Typography component="div" sx={{ fontWeight: 600, fontSize: 18 }}>
          Tickets{" "}
          <span
            style={{
              color: "var(--Blue-Grey-8, var(--Base-Blue-grey-8, #83889E))",
              fontWeight: 400,
            }}
          >
            ({tableProps.countTickets})
          </span>
        </Typography>
        <div
          style={{
            display: "flex",
          }}
        >
          {tableProps.exportIsLoading && (
            <CircularLoader
              sx={{
                marginRight: "1rem",
                width: "2rem !important",
                height: "2rem !important",
              }}
            />
          )}

          <Button
            data-testid="export_btn"
            onClick={tableProps.handleExport}
            variant="contained"
            startIcon={
              <img
                loading="lazy"
                src={ExportsIcon}
                className="img-11"
                alt="export"
              />
            }
            style={{
              background: "var(--Interactive-Bg-T1, #F5F5F5)",
              color: "var(--True-Grey-9, var(--Base-Grey-9, #262626))",
              textTransform: "capitalize",
              height: "100%",
            }}
          >
            Export
          </Button>
        </div>
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer
            sx={{
              maxHeight: {
                sm: "45vh",
                md: "30vh",
                lg: "35vh",
                xl: "37vh",
                xxl: "38vh",
              },
              overflow: "auto",
              scrollbarWidth: "none",
            }}
            className="metricTableContainer"
            onScroll={handleScroll}
          >
            <Table
              stickyHeader
              aria-labelledby="sticky table"
              className="metricTableData"
            >
              <EnhancedTableHead
                headCells={tableProps.tableHeadCells}
                handleSorting={handleOnClickTableHeader}
              />
              <TableBody>
                {tableProps?.tableData?.map((row) => (
                  <TableRow
                    key={row.id}
                    className="ticketsTableRow"
                    onClick={() =>
                      tableProps.handleOpenTicket(row.team, row.id)
                    }
                  >
                    {tableProps.tableKeys.map((key) => (
                      <TableCell
                        key={key}
                        align="left"
                        sx={{
                          paddingY: "0rem",
                          fontSize: "14px",
                          fontFamily: "Rubik",
                        }}
                        onClick={(e) => {
                          if (key == "clickup_link") {
                            e.stopPropagation();
                          }
                        }}
                      >
                        {renderCellContent(key, row)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                {tableProps.dataIsLoading &&
                  "next_url" in tableProps &&
                  tableProps.next_url && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "87vw",
                      }}
                    >
                      <CircularLoader />
                    </div>
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </div>
  );
};
